<template>
  <div style="display: inline-block">{{ getCountDownString(mTime) }}</div>
</template>

<script>
export default {
  props: {
    time: {
      type: [String, Number],
      default: 'time',
    },
  },
  created() {
    this.mTime = this.time;
    this.timeDate = setInterval(() => {
      this.mTime -= 1;
      // console.log(this.mTime)
      if (this.mTime === 0) {
        clearInterval(this.timeDate);
        this.$emit('stop');
      }
    }, 1000);
  },
  data() {
    return {
      mTime: 0,
    };
  },
  mounted() {

  },
  methods: {
    getCountDownString() {
      return this.countDown(this.mTime);
    },
    countDown(time) {
      let m = parseInt((time / 60) % 60);
      m = m < 10 ? `0${m}` : m;
      let s = parseInt(time % 60);
      s = s < 10 ? `0${s}` : s;
      return `${m}:${s}`;
    },
  },
};
</script>

<style lang="scss" scoped>
//.chart {
//  min-width: 250px;
//  width: 100%;
//}
</style>
