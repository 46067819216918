<template>
  <div id="IndentBox" class="">
    <el-tabs v-model="activeName" class="el-tabs__header" @tab-click="handleChangeState">
      <el-tab-pane label="全部订单" name="first">
        <div class="table-box section" style="min-height: 657px">
          <el-table
            :row-class-name="tableRowClassName"
            :header-cell-style="{ background: '#fff', color: '#999999', fontSize: '14px' }"
            size="mini"
            align="center"
            border
            :data="IndentList"
            style="width: 100%;text-align: center"
          >
            <el-table-column prop="orderCode" label="订单编号" width="235">
              <template slot-scope="scope">
                <div
                  @click="ToState(scope.row.id, scope.row.courseId, scope.row.orderCode)"
                  style="cursor: pointer; color: #231815 "
                >
                  {{ scope.row.orderCode }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="courseName"
              label="课程名称"
              min-width="200"
              :show-overflow-tooltip="true"
              style="color: #231815"
            >
              <template slot-scope="scope">
                <div style=" color: #231815 ">
                  {{ scope.row.courseName }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="priceTotal"
              label="课程金额"
              width="153"
              :show-overflow-tooltip="true"
            >
              <template slot-scope="scope">
                <div style="color: #231815">￥{{ scope.row.priceTotal }}</div>
              </template>
            </el-table-column>
            <el-table-column
              prop="orderState"
              label="状态"
              width="196"
              :show-overflow-tooltip="true"
            >
              <template slot-scope="scope">
                <span style="color: #231815;">{{ scope.row.orderState }}</span>
<!--                <span v-if="scope.row.orderState === '待支付'" style="color:#E19500;padding-left: 16px">{{scope.row.time}}</span>-->
                <span v-if="scope.row.orderState === '待支付'" style="color:#E19500;padding-left: 16px">
                  <Time @stop="handleClick" :time="scope.row.createDate" /></span>
              </template>
            </el-table-column>
            <el-table-column
              prop="orderDateString"
              label="下单时间"
              width="214"
              :show-overflow-tooltip="true"
            >
              <template slot-scope="scope">
                <div style="color: #231815">{{ scope.row.orderDateString }}</div>
              </template>
            </el-table-column>
            <el-table-column align="left" label="操作" width="218" prop="IndentList">
              <template slot-scope="scope">
                <el-button
                  type="primary"
                  size="mini"
                  @click="ToPay(scope.row)"
                  v-if="scope.row.order_state == 0"
                >
                  去支付
                </el-button>

                <el-button
                  size="mini"
                  type="primary"
                  plain
                  @click="beforCancel(scope.row)"
                  v-if="scope.row.order_state == 0"
                >
                  取消
                </el-button>

                <!-- <el-button
                  size="mini"
                  type="primary"
                  @click="getInvoice(scope.row)"
                  v-if="scope.row.order_state == 1 && scope.row.invoice_state == 0"
                >
                  开发票
                </el-button> -->

                <!-- <el-button
                  size="mini"
                  type="primary"
                  @click="ToIndentInformation(scope.row)"
                  v-if="scope.row.order_state == 1 && scope.row.invoice_state != 0"
                >
                  查看发票
                </el-button> -->

                <el-button
                  type="primary"
                  size="mini"
                  @click="rebuy(scope.row)"
                  v-if="scope.row.order_state == 2"
                >
                  重新购买
                </el-button>

                <el-button
                  size="mini"
                  icon="el-icon-delete"
                  type="primary"
                  plain
                  @click="open(scope.row)"
                  v-if="scope.row.order_state == 2"
                >
                  删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>

          <el-pagination
            @current-change="handleCurrentChange"
            :current-page="paramIndent.page + 1"
            :page-size="paramIndent.count"
            layout="prev, pager, next, jumper"
            :total="totalCount"
            class="mt-20 pagination-box"
          />
        </div>
      </el-tab-pane>

      <el-tab-pane label="待支付" name="second">
        <div class="table-box section" style="min-height: 657px">
          <el-table
            size="mini"
            align="center"
            :data="notPayData.list"
            :row-class-name="tableRowClassName"
            :header-cell-style="{ background: '#fff', color: '#999', fontSize: '14px' }"
            stripe
            border
            style="width: 100%"
          >
            <el-table-column prop="orderCode" label="订单编号" width="235">
              <template slot-scope="scope">
                <div
                  @click="ToState(scope.row.id, scope.row.courseId, scope.row.orderCode)"
                  style="cursor: pointer; color: #231815"
                >
                  {{ scope.row.orderCode }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="courseName"
              label="课程名称"
              min-width="200"
              :show-overflow-tooltip="true"
              style="color: #231815"
            >
              <template slot-scope="scope">
                <div style=" color: #231815 ">
                  {{ scope.row.courseName }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="priceTotal"
              label="课程金额"
              width="153"
              :show-overflow-tooltip="true"
            >
              <template slot-scope="scope">
                <div style="color: #231815">￥{{ scope.row.priceTotal }}</div>
              </template>
            </el-table-column>
            <el-table-column
              prop="orderState"
              label="状态"
              width="196"
              :show-overflow-tooltip="true"
            >
              <template slot-scope="scope">
                <span style="color: #231815">{{ scope.row.orderState }}</span>
<!--                <span v-if="scope.row.orderState === '待支付'" style="color:#E19500;padding-left: 16px">{{getCountDownString(scope.row.createDate)}}</span>-->
                <span v-if="scope.row.orderState === '待支付'" style="color:#E19500;padding-left: 16px">
                  <Time @stop="getnotPayList" :time="scope.row.createDate" />
                </span>
              </template>
            </el-table-column>
            <el-table-column
              prop="orderDateString"
              label="下单时间"
              width="214"
              :show-overflow-tooltip="true"
            >
              <template slot-scope="scope">
                <div style="color: #231815">{{ scope.row.orderDateString }}</div>
              </template>
            </el-table-column>
            <el-table-column align="left" label="操作" width="218">
              <template slot-scope="scope">
                <el-button type="primary" size="mini" @click="ToPay(scope.row)">
                  去支付
                </el-button>

                <el-button type="primary" size="mini" plain @click="beforCancel(scope.row)">
                  取消
                </el-button>
              </template>
            </el-table-column>
          </el-table>

          <el-pagination
            @current-change="handleCurrentChange"
            :current-page="paramIndent.page + 1"
            :page-size="paramIndent.count"
            layout="prev, pager, next, jumper"
            :total="notPayData.totalCount"
            class="mt-20 pagination-box"
          />
        </div>
      </el-tab-pane>

      <el-tab-pane label="已支付" name="third">
        <div class="table-box section" style="min-height: 657px">
          <el-table
            size="mini"
            align="center"
            :data="PayData.list"
            :row-class-name="tableRowClassName"
            :header-cell-style="{ background: '#fff', color: '#999', fontSize: '14px' }"
            stripe
            border
            style="width: 100%"
          >
            <el-table-column prop="orderCode" label="订单编号" width="235">
              <template slot-scope="scope">
                <div
                  @click="ToState(scope.row.id, scope.row.courseId, scope.row.orderCode)"
                  style="cursor: pointer; color: #231815"
                >
                  {{ scope.row.orderCode }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="courseName"
              label="课程名称"
              min-width="200"
              :show-overflow-tooltip="true"
              style="color: #231815"
            >
              <template slot-scope="scope">
                <div style=" color: #231815 ">
                  {{ scope.row.courseName }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="priceTotal"
              label="课程金额"
              width="153"
              :show-overflow-tooltip="true"
            >
              <template slot-scope="scope">
                <div style="color: #231815">￥{{ scope.row.priceTotal }}</div>
              </template>
            </el-table-column>
            <el-table-column
              prop="orderState"
              label="状态"
              width="196"
              :show-overflow-tooltip="true"
            >
              <template slot-scope="scope">
                <div style="color: #231815">{{ scope.row.orderState }}</div>
              </template>
            </el-table-column>
            <el-table-column
              prop="orderDateString"
              label="下单时间"
              width="214"
              :show-overflow-tooltip="true"
            >
              <template slot-scope="scope">
                <div style="color: #231815">{{ scope.row.orderDateString }}</div>
              </template>
            </el-table-column>
            <el-table-column align="left" label="操作" width="218" prop="PayData.list">
              <!-- <template slot-scope="scope">
                <el-button
                  type="primary"
                  size="mini"
                  @click="getInvoice(scope.row)"
                  v-if="scope.row.invoice_state == 0"
                >
                  开发票
                </el-button>
                <el-button
                  type="primary"
                  size="mini"
                  @click="ToIndentInformation(scope.row)"
                  v-if="scope.row.invoice_state != 0"
                >
                  查看发票
                </el-button>
              </template> -->
            </el-table-column>
          </el-table>
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page="paramIndent.page + 1"
            :page-size="paramIndent.count"
            layout="prev, pager, next, jumper"
            :total="PayData.totalCount"
            class="mt-20 pagination-box"
          />
        </div>
      </el-tab-pane>

      <el-tab-pane label="已取消" name="fourth">
        <div class="table-box section" style="min-height: 657px">
          <el-table
            size="mini"
            align="center"
            :data="cancelData.list"
            :row-class-name="tableRowClassName"
            :header-cell-style="{ background: '#fff', color: '#999', fontSize: '14px' }"
            stripe
            border
            style="width: 100%"
          >
            <el-table-column prop="orderCode" label="订单编号" width="235">
              <template slot-scope="scope">
                <div
                  @click="ToState(scope.row.id, scope.row.courseId, scope.row.orderCode)"
                  style="cursor: pointer; color: #231815"
                >
                  {{ scope.row.orderCode }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="courseName"
              label="课程名称"
              min-width="200"
              :show-overflow-tooltip="true"
              style="color: #231815"
            >
              <template slot-scope="scope">
                <div style=" color: #231815 ">
                  {{ scope.row.courseName }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="priceTotal"
              label="课程金额"
              width="153"
              :show-overflow-tooltip="true"
            >
              <template slot-scope="scope">
                <div style="color: #231815">￥{{ scope.row.priceTotal }}</div>
              </template>
            </el-table-column>
            <el-table-column
              prop="orderState"
              label="状态"
              width="196"
              :show-overflow-tooltip="true"
            >
              <template slot-scope="scope">
                <div style="color: #231815">{{ scope.row.orderState }}</div>
              </template>
            </el-table-column>
            <el-table-column
              prop="orderDateString"
              label="下单时间"
              width="214"
              :show-overflow-tooltip="true"
            >
              <template slot-scope="scope">
                <div style="color: #231815">{{ scope.row.orderDateString }}</div>
              </template>
            </el-table-column>
            <el-table-column align="left" label="操作" width="218">
              <template slot-scope="scope">
                <el-button type="primary" size="mini" @click="rebuy(scope.row)">
                  重新购买
                </el-button>
                <el-button type="primary" plain size="mini" icon="el-icon-delete" @click="open(scope.row)">
                  删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page="paramIndent.page + 1"
            :page-size="paramIndent.count"
            layout="prev, pager, next, jumper"
            :total="cancelData.totalCount"
            class="mt-20 pagination-box"
          ></el-pagination>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import API from '@/api';
import Time from './component/CountDownTextView';

export default {
  components: {
    Time,
  },
  name: 'Indent',
  data() {
    return {
      background: '#f0f9eb',
      activeName: 'first',
      timer: null,
      // 订单参数
      paramIndent: {
        page: 0,
        orderState: '',
        count: 10,
      },
      indent: {
        id: '',
      },
      totalCount: 0, // 总条数
      IndentList: [], // 全部订单数据
      notPayData: '', // 未支付订单
      PayData: '', // 支付订单
      cancelData: '', // 取消订单
      time: '',
      second: '',
      min: '',
    };
  },
  created() {
    this.handleClick();
    // this.getnotPayList()
    // this.getPayList()
    // this.getcancelList()
  },
  methods: {
    //  背景颜色
    tableRowClassName({ rowIndex }) {
      // console.log(rowIndex)
      if (rowIndex % 2 === 0) {
        return 'warning-row';
      }
      return 'success-row';
    },
    handleChangeState() {
      if (this.activeName === 'first') {
        this.handleClick();
      }
      if (this.activeName === 'second') {
        this.getnotPayList();
      }
      if (this.activeName === 'third') {
        this.getPayList();
      }
      if (this.activeName === 'fourth') {
        this.getcancelList();
      }
    },
    // 获取全部订单
    handleClick() {
      this.paramIndent.orderState = '';
      API.Indent(this.paramIndent)
        .then((res) => {
          if (res.message.success === true) {
            this.totalCount = res.message.data.totalCount;
            this.IndentList = res.message.data.list;
          } else {
            this.$message({
              message: res.message.message,
              type: 'error',
            });
          }
        })
        .catch((mgs) => {
          this.$message({
            message: mgs.message.message,
            type: 'warning',
          });
        });
    },
    // 分页器
    handleCurrentChange(page) {
      this.paramIndent.page = page - 1;
      this.handleClick();
      this.getcancelList();
    },
    // 订单详情 -- 状态
    ToState(orderId, courseId, orderCode) {
      window.open(`${process.env.VUE_APP_PAY}PayAfter?orderId=${orderId}&courseId=${courseId}&orderCode=${orderCode}`);
    },
    // 开发票
    getInvoice(row) {
      this.$router.push({
        path: '/PersonalCenter/Indent/Invoice',
        query: {
          orderId: row.id,
          courseId: row.courseId,
          price: row.priceTotal,
        },
      });
    },
    // 删除弹窗
    open(row) {
      this.$confirm('删除后不可恢复，确定要删除吗?', '提示', {
        closeOnClickModal: false,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.handleDelete(row);
          this.handleClick();
        });
      // .catch(() => {
      //   this.$message({
      //     type: 'info',
      //     message: '已取消删除',
      //   });
      // });
    },
    // 删除操作
    handleDelete(row) {
      this.indent.id = row.id;
      API.IndentDelete(this.indent).then((res) => {
        if (res.message.success === true) {
          this.handleClick();
          this.getcancelList();
          this.$message({
            message: res.message.message,
            type: 'success',
          });
        } else {
          this.$message({
            message: res.message.message,
            type: 'warning',
          });
        }
      });
    },

    // 获取待支付表单数据
    getnotPayList() {
      this.paramIndent.orderState = 0;
      API.Indent(this.paramIndent)
        .then((res) => {
          if (res.message.success === true) {
            this.notPayData = res.message.data;
          } else {
            this.$message({
              message: res.message.message,
              type: 'error',
            });
          }
        })
        .catch((mgs) => {
          this.$message({
            message: mgs.message.message,
            type: 'warning',
          });
        });
    },
    // 已支付表单数据
    getPayList() {
      this.paramIndent.orderState = 1;
      API.Indent(this.paramIndent)
        .then((res) => {
          if (res.message.success === true) {
            this.PayData = res.message.data;
          } else {
            this.$message({
              message: res.message.message,
              type: 'error',
            });
          }
        })
        .catch((mgs) => {
          this.$message({
            message: mgs.message.message,
            type: 'warning',
          });
        });
    },
    // 获取已取消表单数据
    getcancelList() {
      this.paramIndent.orderState = 2;
      API.Indent(this.paramIndent)
        .then((res) => {
          if (res.message.success === true) {
            this.cancelData = res.message.data;
          } else {
            this.$message({
              message: res.message.message,
              type: 'warning',
            });
          }
        })
        .catch((mgs) => {
          this.$message({
            message: mgs.message.message,
            type: 'error',
          });
        });
    },
    // 去支付
    ToPay(row) {
      window.open(`${process.env.VUE_APP_PAY}Pay/?id=${row.courseId}&orderId=${row.id}&orderCode=${row.orderCode}`);
    },
    // 取消弹窗
    beforCancel(row) {
      this.$confirm('确定要取消当前课程的购买吗?', '提示', {
        closeOnClickModal: false,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.canCenled(row);
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消购买',
          });
        });
    },
    // 取消操作
    canCenled(row) {
      // this.indent.id = row.id
      API.cancel({
        id: row.id,
      }).then((res) => {
        if (res.message.success === true) {
          this.handleClick();
          this.getnotPayList();
          this.$message({
            message: res.message.message,
            type: 'success',
          });
        } else {
          this.$message({
            message: res.message.message,
            type: 'warning',
          });
        }
      });
    },
    // 重新购买
    rebuy(row) {
      this.indent.id = row.courseId;
      window.open(`${process.env.VUE_APP_PAY}?id=${row.courseId}`);
    },
    // 查看发票
    ToIndentInformation(row) {
      this.$router.push({
        path: '/PersonalCenter/Indent/IndentInformation',
        query: {
          id: row.invoiceId,
        },
      });
    },
  },
};
</script>

<style scoped>
/*.pagination-box {*/
/*  text-align: right;*/
/*  margin-right: 30px;*/
/*}*/
/*::v-deep .el-table__body {*/
/*  border-left: none;*/
/*}*/
/*::v-deep .el-table--group,*/
/*.el-table--border {*/
/*  border: none;*/
/*}*/
::v-deep .el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
  text-align: center;
}
::v-deep .el-table th.el-table__cell {
  text-align: center;
}
/*::v-deep .el-table--group::after,*/
/*.el-table--border::after {*/
/*  width: 0;*/
/*}*/
/*::v-deep .el-table--border td:last-child {*/
/*  border: none;*/
/*}*/
/*::v-deep .el-table::before {*/
/*  height: 0;*/
/*}*/
/*::v-deep .el-table th.is-leaf {*/
/*  border-color: #ffdd8b;*/
/*}*/
/*::v-deep .el-table--border td {*/
/*  border-color: #ffdd8b;*/
/*}*/
/*::v-deep .el-tabs__header {*/
/*  margin: 0 0 30px;*/
/*}*/
/*::v-deep .el-table tr.warning-row {*/
/*  background: #fff;*/
/*}*/
/*::v-deep .el-table tr.success-row {*/
/*  background: rgb(255, 250, 237);*/
/*}*/
</style>
